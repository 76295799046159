import React from 'react'

export default () => (
    <iframe
        className='google-map'
        width="600" 
        height="450" 
        frameBorder="0" 
        style={{ border:0, width: "80%" }} 
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJsx3dnkqo2EcR1EoAyOZB9_Q&key=AIzaSyDsT8VE_M_kyqfj9SdXm8SEDF0Ei42kQUs"
        allowFullScreen
    />

)